import styled from 'styled-components'

export const WaveformContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 150px;
  width: 100%;
  background: transparent;
  border: 2px solid #756bc9;
  border-radius: 10px;
`;

export const Wave = styled.div`
    width:100%;
    height: 120px;
    align-items: center;
    //border: 1px solid #ddd;
    //border-radius: 4px;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-right: 0.5rem;
  `;

export const PlayButton = styled.button`
    display:flex;
    justify-content: center;
    align-items: center;
    width: 130px;
    height: 110px;
    background: #E8E7F9;
    border-radius: 50%;
    border: none;
    outline: none;
    cursor: pointer;
    padding-bottom: 3px;
    margin-right: 0.5rem;

    &:hover {
        background: #c0b3f2;
    }
  `;