import React, {useEffect, useState} from 'react';
import { UserOutlined, EyeOutlined, MailOutlined } from '@ant-design/icons';
import { Row, Button, Form, Input, Image } from 'antd';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { Alert, Space } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import Api from "../../interceptor/api";
import {
  loginSubmit,
  loginSuccess,
  loginError,
} from "../../redux/reducers/AuthReducer";
import {Utils} from "../../utils";
let path = process.env.PUBLIC_URL;
let logo = "/images/converse-smartly.svg";

const ResetPassword = (props) => {

  const [passwordVisible, setPasswordVisible] = React.useState(false);

  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const errorMessages = useSelector((state) => state.AuthReducer.error);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  const onFinish = async (values: any) => {
    console.log('Received values of form: ', values);
    const {newPassword, confirNewPassword} = values;
    if (newPassword === confirNewPassword) {
      try {
            const response = await Api.post(`/password/reset`, {
              password: newPassword,
              confirm_password: confirNewPassword,
              email: String(searchParams.get('email')),
              token: String(searchParams.get('token')),
            });
            const data = response.data;
            console.log(response)
            if (response.status === 200) {
              Utils.createNotification(`Password Reset Successful`, "success");
              navigate("/login")
              console.log({data})
            } else {
              console.log({response})
              Utils.createNotification(`Error! Something went wrong`, "error");
            }
          } catch (err) {
            dispatch(loginError(err.response.data.detail));
          }
    }
  };

  useEffect(() => {
    const email = searchParams.get('email');
    const token = searchParams.get('token');

    if (!email || !token) {
      navigate('/login');
    }
  }, [])

  return (
    <>
      <Row className='wrapper' type="flex" justify="center" style={{ height: "100vh" }} align="middle">
        <div className='login-wrapper'>
          <div className='login-header'>
            <Image width={280} src={path + logo} preview={false} />
            <h1>Reset Password</h1>
          </div>
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            {!!errorMessages && (
              <Space direction="vertical" style={{ width: "100%", marginBottom: "1em" }}>
               <Alert
                message="Error"
                description={errorMessages}
                type="error"
                showIcon
              />
            </Space>
            )}
            <Form.Item
              name="newPassword"
              rules={[{ required: true, message: 'Please input your New Password!' },
                {
                  // message: "Please enter a valid Email",
                  validator: (_, value) => {
                    if (/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(value)) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject('Password must include atleast minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character');
                    }
                  }
                }]}
            >
              <Input
                prefix={<EyeOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="New Password"
              />
            </Form.Item>

            <Form.Item
              name="confirNewPassword"
              rules={[{ required: true, message: 'Please input your Confirm New Password!' },
                {
                  // message: "Please enter a valid Email",
                  validator: (_, value) => {
                    if (/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(value)) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject('Password must include atleast minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character');
                    }
                  }
                }]}
            >
              <Input
                prefix={<EyeOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Confirm New Password"
              />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" className="login-form-button w-100">
                Reset Password
              </Button>
            </Form.Item>
            
          </Form>
        </div>
      </Row>
    </>
  )
}

export default ResetPassword