import React, { useState } from 'react';
import { UserOutlined, EyeOutlined, MailOutlined } from '@ant-design/icons';
import { Row, Button, Form, Input, Image } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { Alert, Space } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import Api from "../../interceptor/api";
import {
  loginSubmit,
  loginSuccess,
  loginError,
} from "../../redux/reducers/AuthReducer";
import { Utils } from "../../utils";
let path = process.env.PUBLIC_URL;
let logo = "/images/converse-smartly.svg";

const Login = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const errorMessages = useSelector((state) => state.AuthReducer.error);
  const navigate = useNavigate();

  const onFinish = async (values: any) => {
    console.log('Received values of form: ', values);
    dispatch(loginSubmit());
    let urlencoded = new URLSearchParams();
    urlencoded.append("username", values.name);
    urlencoded.append("password", values.password);
    try {
      const response = await Api.post(`/auth/login`, urlencoded);
      const data = response.data;
      console.log(response)
      if (response.status === 200) {
        localStorage.setItem("user", JSON.stringify(data.user));
        dispatch(loginSuccess(response.data.user));
        Utils.createNotification(`User Log in Successful`, "success");
        navigate("/")
        console.log({ data })
      } else {
        console.log({ response })
        setError('Error Logging in!')
      }
    } catch (err) {
      dispatch(loginError(err.response.data.detail));
    }
  };
  return (
    <>
      <Row className='wrapper' type="flex" justify="center" style={{ height: "100vh" }} align="middle">
        <div className='login-wrapper'>
          <div className='login-header'>
            <Image width={280} src={path + logo} preview={false} />
            <h1>Please Login to your account</h1>
          </div>
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            {!!errorMessages && (
              <Space direction="vertical" style={{ width: "100%", marginBottom: "1em" }}>
                <Alert
                  message="Error"
                  description={errorMessages}
                  type="error"
                  showIcon
                />
              </Space>
            )}
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Please input your Email address!',
                },
                {
                  validator: (_, value) => {
                    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject('Please enter a valid Email');
                    }
                  }
                }
              ]}
            >
              <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email address" />
            </Form.Item>
            <Form.Item
              name="password"
              rules={[{ required: true, message: 'Please input your Password!' },
              {
                // message: "Please enter a valid Email",
                validator: (_, value) => {
                  if (/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(value)) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject('Password must include atleast minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character');
                  }
                }
              }]}
            >
              <Input
                prefix={<EyeOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            <Link className="login-form-forgot link" to={'/forgot-password'}>
              <p>Forgot your password?</p>
            </Link>
            <Form.Item>
              <Button type="primary" htmlType="submit" className="login-form-button w-100">
                Log in
              </Button>
            </Form.Item>
            <p>Don't have an account?  <Link to={'/register'} className='link'>Register now!</Link></p>
          </Form>
        </div>
      </Row>
    </>
  )
}

export default Login