
let PAY_AS_YOU_GO_PRICE_ID = "price_1NrfcXC95bUId8wTzddJ6yhB";
let PERSONAL_USER_PRICE_ID = "price_1NrffgC95bUId8wT0dRP8nHf"
let PROFESSIONAL_PRICE_ID = "price_1Nrfh2C95bUId8wT9NrjgWel"

if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
    PAY_AS_YOU_GO_PRICE_ID = "price_1NsM8IC95bUId8wTZxCVDn7Z";
    PERSONAL_USER_PRICE_ID = "price_1NsM7lC95bUId8wT6uQ6cGYt"
    PROFESSIONAL_PRICE_ID = "price_1NsLx8C95bUId8wTK64tlLw3"
}

const SUPPORTED_LANGUAGES = [
    { value: 'en-US', label: 'English (US)' },
    { value: 'en-GB', label: 'English (UK)' },
    { value: 'en-AU', label: 'English (Australia)' },
    { value: 'fr-FR', label: 'French' },
    { value: 'de-DE', label: 'German' },
    { value: 'es-ES', label: 'Spanish' },
];

const LIVE_TRANSCRIPTION_SUPPORTED_LANGUAGES = [
    { label: "English (US)", value: "en-US" },
    { label: "English (UK)", value: "en-GB" },
    { label: "English (Australian)", value: "en-AU" },
    { label: "Spanish (US)", value: "es-US" },
    { label: "French (France)", value: "fr-FR" },
    { label: "Canadian French", value: "fr-CA" },
    { label: "German", value: "de-DE" },
    { label: "Italian", value: "it-IT" },
    { label: "Portuguese (Brazilian)", value: "pt-BR" },
    { label: "Mandarin Chinese", value: "zh-CN" },
    { label: "Japanese", value: "ja-JP" },
    { label: "Korean", value: "ko-KR" },
    { label: "Hindi", value: "hi-IN" },
    { label: "Thai", value: "th-TH" }
]

const ALL_SUPPORTED_LANGUAGES = [
    { label: "English (US)", value: "en-US" },
    { label: "English (UK)", value: "en-GB" },
    { label: "English (Australian)", value: "en-AU" },
    { label: "Spanish (US)", value: "es-US" },
    { label: "French (France)", value: "fr-FR" },
    { label: "Canadian French", value: "fr-CA" },
    { label: "German", value: "de-DE" },
    { label: "Italian", value: "it-IT" },
    { label: "Portuguese (Brazilian)", value: "pt-BR" },
    { label: "Mandarin Chinese", value: "zh-CN" },
    { label: "Japanese", value: "ja-JP" },
    { label: "Korean", value: "ko-KR" },
    { label: "Hindi", value: "hi-IN" },
    { label: "Thai", value: "th-TH" }
]

const PLANS = {
    PAY_AS_YOU_GO: 'Pay as you go',
    PERSONAL_USER: 'Personal user',
    PROFESSIONAL: 'Professional',
}

const MIXPANEL_EVENTS = {
    SIGN_UP: "SIGN_UP",
    LOGIN: "LOGIN",
    LOGOUT: "LOGOUT",
    NAVIGATION: "NAVIGATION",
    LIVE_TRANSCRIPTION_CREATED: "LIVE_TRANSCRIPTION_CREATED",
    TRANSCRIPTION_CREATED: "TRANSCRIPTION_CREATED",
    TRANSCRIPTION_DOWNLOADED: "TRANSCRIPTION_DOWNLOADED",
}

export {
    PLANS,
    PAY_AS_YOU_GO_PRICE_ID,
    PERSONAL_USER_PRICE_ID,
    PROFESSIONAL_PRICE_ID,
    SUPPORTED_LANGUAGES,
    ALL_SUPPORTED_LANGUAGES,
    LIVE_TRANSCRIPTION_SUPPORTED_LANGUAGES,
    MIXPANEL_EVENTS
}
