import "react-notifications/lib/notifications.css";
import { useSelector } from "react-redux";
import { setToken } from "./interceptor/api";
import AppRoutes from './AppRoutes';

function App() {
  const user = useSelector((state) => state.AuthReducer.user);

  if (user) {
    setToken("Authorization", `Bearer ${user.access_token}`);
  }
  return (
    <div className="App">
      <AppRoutes />
    </div>
  );
}

export default App;
