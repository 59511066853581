import React from 'react';
import { UserOutlined, EyeOutlined, UserAddOutlined, SolutionOutlined, MailOutlined, FileUnknownOutlined } from '@ant-design/icons';
import {Row, Button, Form, Input, Image, Select, Col, Space, Alert} from 'antd';
import {Link, useNavigate} from 'react-router-dom';
import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import Api from "../../interceptor/api";
import {Utils} from "../../utils";
let path = process.env.PUBLIC_URL;
let logo = "/images/converse-smartly.svg";

const Register = () => {
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const [industry, setIndustry] = useState("");
  const errorMessages = useSelector((state) => state.AuthReducer.error);
  const navigate = useNavigate();

  const onFinish = async (values: any) => {
    console.log('Received values of form: ', values);
    if (!industry) {
      setError("Please select an Industry");
      return
    }
    try {
      let response = await Api.post(`/auth/register`, {
        username: values.email,
        name: values.name,
        email: values.email,
        password: values.password,
        job_role: values.jonRole,
        industry: industry
      });
      console.log(response)
      if (response.status === 201) {
        // setLoader(false);
        // setErrorMessages(false);
        Utils.createNotification('Registered Successfully', "success");
        navigate("/login");
      }
    } catch (err) {
      console.log(err)
      setError(err.response.data.detail);
      // setLoader(false);
    }
  };

  const onChange = (value: string) => {
    console.log(`selected ${value}`);
    setIndustry(value);
  };

  const onSearch = (value: string) => {
    console.log('search:', value);
  };

  return (
    <>
      <Row className='wrapper' type="flex" justify="center" style={{ height: "100vh" }} align="middle">
        <div className='login-wrapper register-form'>
          <div className='login-header'>
            <Image width={280} src={path + logo} preview={false} />
            <h1>Create your account</h1>
          </div>
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
          >
            {!!error && (
              <Space direction="vertical" style={{ width: "100%", marginBottom: "1em" }}>
               <Alert
                message="Error"
                description={error}
                type="error"
                showIcon
              />
            </Space>
            )}
          <Row>
            <Col span={12}>
            <Form.Item
              name="name"
              rules={[{ required: true, message: 'Please input your Name!' }]}
            >
              <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Name" />
            </Form.Item>
            </Col>
            <Col span={12}>
            <Form.Item
              name="email"
              rules={[{ required: true, message: 'Please input your Email address!' },
                {
                  validator: (_, value) => {
                    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(value)) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject('Please enter a valid Email');
                    }
                  }
                }]}
            >
              <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email address" />
            </Form.Item>
            </Col>
          </Row>
            
          <Row>
            <Col span={12}>
            <Form.Item
              name="password"
              rules={[
                { required: true, message: 'Please input your Password!' },
                {
                  validator: (_, value) => {
                    if (/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/.test(value)) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject('Password must include at-least minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character');
                    }
                  }
                }]}
            >
              <Input
                prefix={<EyeOutlined className="site-form-item-icon" />}
                type="password"
                placeholder="Password"
              />
            </Form.Item>
            </Col>
            <Col span={12}>
            <Form.Item
              name="companyName"
              rules={[{ required: false, message: 'Please input your Company Name!' }]}
            >
              <Input prefix={<FileUnknownOutlined className="site-form-item-icon" />} placeholder="Company Name" />
            </Form.Item>
</Col>
          </Row>
           
            <Form.Item
              name="jonRole"
              rules={[{ required: true, message: 'Please input your Job Role!' }]}
            >
              <Input prefix={<SolutionOutlined className="site-form-item-icon" />} placeholder="Job Role" />
            </Form.Item>
            <Form.Item className='industry-select'
            >
            <Select
              showSearch
              style={{ width: '100%' }}
              placeholder="Industry"
              optionFilterProp="children"
              onChange={onChange}
              onSearch={onSearch}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
              }
              options={[
                {
                  value: 'HealthcareAndMedical',
                  label: 'Healthcare and medical',
                },
                {
                  value: 'LegalandCourtReporting',
                  label: 'Legal and court reporting',
                },
                {
                  value: 'BusinessAndfinancial',
                  label: 'Business and financial',
                },
                {
                  value: 'EducationAndELearning',
                  label: 'Education and e-learning',
                },
                {
                  value: 'MediaAndEntertainment',
                  label: 'Media and entertainment',
                },
                {
                  value: 'Customer service and call center',
                  label: 'CustomerServiceAndCallCenter',
                },
                {
                  value: 'GovernmentAndPublicSector',
                  label: 'Government and public sector',
                },
                {
                  value: 'TechnologyAndSoftwareDevelopment',
                  label: 'Technology and software development',
                }
              ]}
            />
            </Form.Item>
            
            <Form.Item>
              <Button type="primary" htmlType="submit" className="login-form-button w-100">
                Sign Up
              </Button>
            </Form.Item>
            <p>Already have an account?  <Link to={'/login'} className='link'>Log in</Link></p>
          </Form>
        </div>
      </Row>
    </>
  )
}

export default Register