let API_URL = 'http://localhost:8080';
let AUTH_SERVER_URL = 'http://localhost:3001';

if (process.env.REACT_APP_ENVIRONMENT === 'qa') {
  API_URL = 'https://qa-conversemartly.folio3.com:5000/api/';
  AUTH_SERVER_URL = 'https://login-dev.folio3.ai';
}
if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
  API_URL = 'https://www.conversesmartly.com/api/';
  AUTH_SERVER_URL = 'https://login.folio3.ai';
}
export { API_URL, AUTH_SERVER_URL };
