import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../redux/reducers/AuthReducer";
import { Utils } from "../utils";
import Dashboard from './Dashboard';
import LandingPage from './LandingPage';

const Home = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.AuthReducer.user);
  const logout = async () => {
    try {
      localStorage.clear();
      dispatch(logoutUser());
      Utils.createNotification("User successfully Logged out!", 'success')
    } catch (e) {
      console.log(`Axios request failed: ${e}`);
    }
  };
  return (
    <>
      {!user && (
        <>
          <LandingPage></LandingPage> 
        </>
      )}
      {!!user && (
        <>
          <Dashboard logout={logout} />
        </>
      )}
    </>
  )
}

export default Home