import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import type { UploadProps } from 'antd';
import { Col, Image, Row, Modal, Form, Input, Button, Select, message, Upload } from 'antd'
import { useNavigate } from 'react-router-dom';
import Api from '../interceptor/api';
import Utils from '../utils';
import {LIVE_TRANSCRIPTION_SUPPORTED_LANGUAGES, SUPPORTED_LANGUAGES} from "../config/constants";

let path = process.env.PUBLIC_URL;
let microphone = path + "/images/microphone.svg";
let upload = path + "/images/upload.svg";
let uploadIcon = path + "/images/upload-icon.svg";
// let play = path + "/images/play.svg";

const { Dragger } = Upload;

const GetStarted = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [transcriptionTitle, setTranscriptionTitle] = useState("");
    const [isModalOpenTwo, setIsModalOpenTwo] = useState(false);
    const [fileList, setFileList] = useState(false);
    const [form] = Form.useForm();
    const [isFileUploadDisabled, setIsFileUploadDisabled] = useState(false);
    const user = useSelector((state) => state.AuthReducer.user);
    const [fileIds, setFileIds] = useState([]);
    const [duration, setDuration] = useState(null);
    const [language, setLanguage] = useState('en-US');
    const [filename, setFilename] = useState("");
    const [isMedical, setIsMedical] = useState(false);
    const navigate = useNavigate();

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setTranscriptionTitle(null)
    };

    const showModalAudioVideo = () => {
        setIsModalOpenTwo(true);
    };

    const handleOkTwo = () => {
        setIsModalOpenTwo(false);
    };

    const handleCancelTwo = () => {
        setIsModalOpenTwo(false);
    };

    const transcriptionVocabularyDropdownChange = (value: string) => {
        console.log(`selected ${value}`);
        if (value === "medical") {
            setIsMedical(true)
        } else {
            setIsMedical(false)
        }
    };

    const props: UploadProps = {
        name: 'file',
        multiple: false,
        accept: "audio/mpeg",
        maxCount: 1,
        disabled: isFileUploadDisabled,
        method: "PUT",
        fileList: fileList,
        action: ({name, type, size}) => {
            return new Promise(async (resolve, reject) => {
                const response = await Api.post('/generate-url/signed-url', {
                    "filename": name,
                    "content_type": type,
                    "file_size": size,
                    "storage_provider": "AWS",
                })
                setFileIds(prev => [...prev, response.data.id]);

                // TODO Generate AWS presigned URL here
                resolve(response.data.url);
            })
        },
        async beforeUpload(file, fileList) {
            /*console.log('fileList.length: ', fileList.length);
            console.log('fileList: ', fileList);
            console.log('file: ', file);
            console.log('file name: ', file.name);*/
            const duration = await Utils.getVideoDuration(file);
            setDuration(duration)
            setFilename(file.name)
            const available_seconds = (user.total_allocated_minutes*60) - user.minutes_used
            console.log({ duration, available_seconds })
            if (!file.name.toLowerCase().includes('.mp3')) {
                message.warning("Only mp3 files are supported. Please Upload an mp3 file.")
                return Upload.LIST_IGNORE;
            }
            if ((duration) > available_seconds) {
                message.warning("Remaining minutes ended, Please upgrade your plan.")
                return Upload.LIST_IGNORE;
            }
            return true;
        },
        onChange(info) {
            setFileList([...info.fileList])
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                setIsFileUploadDisabled(true);
                // console.log({info})
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e) {
            console.log('Dropped files', e.dataTransfer.files);
        },
    };

    useEffect(() => {
        if (!isModalOpenTwo) {
            setFileList([]);
            setIsFileUploadDisabled(false);
            setFileIds([])
        }
    }, [isModalOpenTwo]);

    const onTranscribeClick = async (e) => {
        let languageCode;
        SUPPORTED_LANGUAGES.some((l) => {
            if (l.value === language){
                languageCode = l.label
                return true;
            }
            return false;
        })
        try {
            const response = await Api.post('/transcription/job', {
                "language": languageCode,
                "language_code": language,
                "multiple_speakers": true,
                "provider": "AWS",
                "storage_provider": "AWS",
                "file_ids": fileIds,
                "duration": duration,
                filename: filename,
                "is_Medical": isMedical,
              })
              console.log({onTranscribeClick: response});
              if (response.status === 200) {
                setIsModalOpenTwo(false);
                Utils.createNotification("Task created!", "success");
                navigate(0);
              }
        } catch (error) {
            console.log(error)
        }
    }

    const submitLiveTranscription = () => {
        if (transcriptionTitle?.trim()) {
            console.log(transcriptionTitle, language, isMedical)
            navigate("/transcribe", {
                state: {
                    language,
                    isMedical,
                    transcriptionTitle
                }
            })
        } else {
            message.error("Please Enter a valid title")
            setTranscriptionTitle("")
        }
    };

    return (
        <>
            <Row>
                <Col span={20} offset={2} className='get-started-wrapper inner-wrapper'>
                    <h3 className='primary-title'>Get Started!</h3>
                    <Row className='get-started-row' gutter={16}>
                        <Col span={12} onClick={showModal} className='cursor-pointer '>
                            <Row className='get-started-col color-variant-1'>
                                <Col>
                                    <Row align="middle">
                                        <Col>
                                            <div className='get-started-img'>
                                                <Image src={microphone} preview={false} alt='Live Transcription' />
                                            </div>
                                        </Col>
                                        <Col><p style={{ color: '#000' }}>Live Transcription</p></Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12} onClick={showModalAudioVideo} className='cursor-pointer '>
                            <Row className='get-started-col color-variant-2'>
                                <Col>
                                    <Row align="middle">
                                        <Col>
                                            <div className='get-started-img'>
                                                <Image src={upload} preview={false} alt='Upload audio/video' />
                                            </div>
                                        </Col>
                                        <Col><p>Upload audio/video</p></Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        {/* <Col span={8}>
                            <Row className='get-started-col color-variant-3'>
                                <Col>
                                    <Row align="middle">
                                        <Col>
                                            <div className='get-started-img'>
                                                <Image src={play} preview={false} alt='Play sample audio' />
                                            </div>
                                        </Col>
                                        <Col><p>Play sample audio</p></Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col> */}
                    </Row>
                </Col>
            </Row>

            {/* Live Transcription Modal */}

            <Modal
                className='record-audio-modal'
                title="New Recording"
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                centered
                okText="Start"
                footer={null}>
                <Form form={form} name="validateOnly" layout="vertical" autoComplete="off">
                    <Form.Item
                        name="name"
                        label="Title"
                        rules={[{ required: false, message: 'Title' }]}
                    >
                        <Input placeholder="Title" onChange={e => setTranscriptionTitle(e.target.value)} value={transcriptionTitle}/>
                    </Form.Item>
                    <Form.Item>
                        <div className='ant-form-item-label'>
                            <label htmlFor="Preferences" >Preferences</label>
                        </div>
                        <Select
                            className="language-select"
                            defaultValue="en-US"
                            direction="vertical" style={{ width: "100%", marginBottom: "1em" }}
                            onChange={setLanguage}
                            options={LIVE_TRANSCRIPTION_SUPPORTED_LANGUAGES}
                        />
                        <Select
                            className="vocabulary-select"
                            defaultValue="general"
                            direction="vertical" style={{ width: "100%", marginBottom: "1em" }}
                            onChange={transcriptionVocabularyDropdownChange}
                            options={[
                                { value: 'general', label: 'General' },
                                { value: 'medical', label: 'Medical' }
                            ]}
                        />

                        <Row justify="end">
                            <Button type="primary" htmlType="submit" onClick={submitLiveTranscription}>
                                Start
                            </Button>
                        </Row>


                    </Form.Item>
                </Form>
            </Modal>

            {/* File Upload Modal */}

            <Modal
                className='record-audio-modal'
                width={800}
                title="New file upload"
                open={isModalOpenTwo}
                onOk={handleOkTwo}
                onCancel={handleCancelTwo}
                centered
                okText="Start"
                footer={null}>
                <Form form={form} name="validateOnly" layout="vertical" autoComplete="off">
                    <Form.Item>
                        <div className='ant-form-item-label'>
                            <label htmlFor="Preferences" >Preferences</label>
                        </div>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Select
                                    className="language-select"
                                    defaultValue="en-US"
                                    direction="vertical" style={{ width: "100%", marginBottom: "1em" }}
                                    onChange={setLanguage}
                                    options={SUPPORTED_LANGUAGES}
                                />
                            </Col>
                            <Col span={12}>
                                <Select
                                    className="vocabulary-select"
                                    defaultValue="general"
                                    direction="vertical" style={{ width: "100%", marginBottom: "1em" }}
                                    onChange={transcriptionVocabularyDropdownChange}
                                    options={[
                                        { value: 'general', label: 'General' },
                                        { value: 'medical', label: 'Medical' },
                                        // { value: 'legal', label: 'legal' },
                                    ]}
                                />
                            </Col>
                        </Row>

                    </Form.Item>
                    <Form.Item>
                        <hr />
                        <div className='ant-form-item-label'>
                            <label htmlFor="Preferences" >Upload file</label>
                        </div>

                        <Dragger {...props} className='audio-video-uploader' >
                            <p className="ant-upload-drag-icon">
                                <Image src={uploadIcon} preview={false} alt='Play sample audio' />
                            </p>
                            <p className="ant-upload-text">Drag and drop files or <span>Browse</span></p>
                            <p className="ant-upload-hint">Only mp3 files are allowed.</p>
                        </Dragger>

                        <Row justify="end">
                            <Button type="primary" htmlType="submit" onClick={onTranscribeClick} disabled={!isFileUploadDisabled}>
                                Transcribe
                            </Button>
                        </Row>
                    </Form.Item>
                </Form>
            </Modal>
        </>
    )
}

export default GetStarted