import axios from "axios";
import { API_URL } from "../config/environment";
import store from "../redux/store";
import { logoutUser } from "../redux/reducers/AuthReducer";

const Api = axios.create({
  baseURL: `${API_URL}`,
});

export const setToken = (key, token) => {
  if (token) {
    //applying token
    Api.defaults.headers.common[key] = token;
  } else {
    //deleting the token from header
    delete Api.defaults.headers.common[key];
  }
};

// Request interceptor for API calls
Api.interceptors.request.use(
  async (config) => {
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
Api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      store.dispatch(logoutUser());
      localStorage.clear();
    }
    return Promise.reject(error);
  }
);

export default Api;
