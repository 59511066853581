import { Layout } from 'antd';
import {useEffect} from 'react';
import GetStarted from './GetStarted';
import Transcriptions from './Transcriptions';
import Api from '../interceptor/api';
import { useDispatch } from 'react-redux';
import { userUpdate } from '../redux/reducers/AuthReducer';

const Dashboard = (props) => {
    const dispatch = useDispatch();

    useEffect(() => {
        const init = async () => {
            try {
                const { data } = await Api.get('/users/me');
                dispatch(userUpdate(data))
            } catch (error) {
                console.log(error);
            }
        }
        init();
    }, [])

    return (
        <Layout>
            <GetStarted />
            <Transcriptions />
        </Layout>
    );
};
export default Dashboard;