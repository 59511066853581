import React, { useEffect, useState } from 'react';
import {
    Table,
    Col,
    Row,
    theme,
    Button,
    Skeleton,
    Progress,
    Tabs,
} from 'antd';
import WaveSurfer from "wavesurfer.js";
// import RecordPlugin from 'https://unpkg.com/wavesurfer.js@7/dist/plugins/record.esm.js'
import RecordPlugin from './common/RecordPlugin'
import Api from '../interceptor/api';
import { Utils } from '../utils'
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
    userUpdate
} from "../redux/reducers/AuthReducer";
import Word from './WordTimings';
import {
    Wave,
    WaveformContainer,
    PlayButton
} from './WaveSurfer';
import { LIVE_TRANSCRIPTION_SUPPORTED_LANGUAGES, MIXPANEL_EVENTS } from "../config/constants";
import { DownloadOutlined } from "@ant-design/icons";

// eslint-disable-next-line
const marshaller = require("@aws-sdk/eventstream-marshaller"); // for converting binary event stream messages to and from JSON
// eslint-disable-next-line
const util_utf8_node = require("@aws-sdk/util-utf8-node"); // utilities for encoding and decoding UTF8
// eslint-disable-next-line
const mic = require('microphone-stream'); // collect microphone input as a stream of raw bytes
// eslint-disable-next-line
const audioUtils = require('../lib/aws/audioUtils');  // for encoding audio data as PCM

// our converter between binary event streams messages and JSON
const eventStreamMarshaller = new marshaller.EventStreamMarshaller(util_utf8_node.toUtf8, util_utf8_node.fromUtf8);

const { TabPane } = Tabs;

let micStream;
let inputSampleRate;
let sampleRate = 44100;
let socket;

function fmtMSS(s) { return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s }

const getAudioEventMessage = (buffer) => {
    // wrap the audio data in a JSON envelope
    return {
        headers: {
            ':message-type': {
                type: 'string',
                value: 'event'
            },
            ':event-type': {
                type: 'string',
                value: 'AudioEvent'
            }
        },
        body: buffer
    };
}

const convertAudioToBinaryMessage = (audioChunk) => {
    let raw = mic.toRaw(audioChunk);

    if (raw == null)
        return;

    // downsample and convert the raw audio bytes to PCM
    let downsampledBuffer = audioUtils.downsampleBuffer(raw, inputSampleRate, sampleRate);
    let pcmEncodedBuffer = audioUtils.pcmEncode(downsampledBuffer);

    // add the right JSON headers and structure to the message
    let audioEventMessage = getAudioEventMessage(Buffer.from(pcmEncodedBuffer));

    //convert the JSON object + headers into a binary event stream message
    let binary = eventStreamMarshaller.marshall(audioEventMessage);

    return binary;
}

// Create an instance of WaveSurfer
let wavesurfer = null
// Initialize the Record plugin
let record

const Transcribe = ({ logout }) => {
    const dispatch = useDispatch();
    const { state } = useLocation();
    const [dictionary, setDictionary] = useState(false);
    const [limitExceeded, setLimitExceeded] = useState(false);
    const [currentUsage, setCurrentusage] = useState(false);
    const [totalMinutes, setTotalMinutes] = useState(false);
    const [startTime, setStartTime] = useState(0);
    const [streamingTimeout, setStreamingTimeout] = useState(false);
    const [filename, setFilename] = useState("");
    const [hideLiveRecording, setHideLiveRecording] = useState(false);
    const [transcribeSocket, setTranscribeSocket] = useState(null);
    const [transcript, setTranscript] = useState('');
    const [entities, setEntities] = useState('');
    const [keyPhrases, setKeyPhrases] = useState('');
    const [sentiment, setSentiment] = useState(null);
    const [words, setWords] = useState('');
    const [transcriptsList, setTranscriptsList] = useState([])
    const [language, setLanguage] = useState("English (US)")
    const [transcriptionConfig, setTranscriptionConfig] = useState({})
    const [transcriptionDate, setTranscriptionDate] = useState(new Date().toJSON().slice(0, 10).replace(/-/g, ' | '))
    const {
        token: { colorBgContainer },
    } = theme.useToken();
    const [isRecording, setIsRecording] = useState(false);
    const user = useSelector((state) => state.AuthReducer.user);
    let params = useParams();
    const [id, setId] = useState(params.id);
    const [summary, setSummary] = useState("");

    const handleEventStreamMessage = (messageJson) => {
        let results = messageJson.Transcript.Results;

        if (results.length > 0) {
            if (results[0].Alternatives.length > 0) {
                let awsTranscript = results[0].Alternatives[0].Transcript;
                console.log({ results })

                // fix encoding for accented characters
                awsTranscript = decodeURIComponent(escape(awsTranscript));
                setTranscript(prev => awsTranscript)

                // if this transcript segment is final, add it to the overall transcription
                if (!results[0].IsPartial) {
                    let speakerLabel = ''
                    results[0].Alternatives[0].Items.forEach(item => {
                        console.log({ item })
                        if (item.Speaker) {
                            speakerLabel = item.Speaker
                        }
                    })
                    setTranscriptsList(prev => [
                        ...prev, {
                            startTime: results[0].StartTime,
                            endTime: results[0].EndTime,
                            speaker: speakerLabel,
                            transcript: awsTranscript,
                        }])
                    console.log('Transcript:', transcript)
                    setTranscript("")
                    console.log('TranscriptList:', transcriptsList)
                }
            }
        }
    }

    const wireSocketEvents = (socket) => {
        // handle inbound messages from Amazon Transcribe
        socket.onmessage = (message) => {

            //convert the binary event stream message to JSON
            let messageWrapper
            let messageBody
            try {
                console.log(message)
                messageWrapper = eventStreamMarshaller.unmarshall(Buffer(message.data));
                console.log('messageWrapper', messageWrapper)
                messageBody = JSON.parse(String.fromCharCode.apply(String, messageWrapper.body));
                console.log("message Body", messageBody)
                if (messageWrapper.headers[":message-type"].value === "event") {
                    handleEventStreamMessage(messageBody);
                }
                else {
                    console.log(messageBody.Message);
                }
            } catch (error) {
                console.log(error)
                messageBody = JSON.parse(String.fromCharCode.apply(String, messageWrapper.body));
                if (messageWrapper.headers[":message-type"].value === "event") {
                    handleEventStreamMessage(messageBody);
                }
                else {
                    console.log(messageBody.Message);
                }

            }
        };

        socket.onerror = function () {
            socket.close()
            micStream.stop();
            console.log('WebSocket connection error. Try again.');
        };

        socket.onclose = function (closeEvent) {
            micStream.stop();
            console.log('socket closed')

            // the close event immediately follows the error event; only handle one.
            if (closeEvent.code !== 1000) {
                console.log('</i><strong>Streaming Exception</strong><br>' + closeEvent.reason);
            }
        };
    }

    useEffect(() => {
        const updateUserLimits = async () => {
            const response = await Api.get("/users/me")
            console.log({ user: response })
            dispatch(userUpdate(response.data));
        }
        updateUserLimits();
    }, [isRecording])

    const closeSocket = async () => {
        let newList = transcriptsList;

        if (transcript) {
            newList = [
                ...transcriptsList, {
                    startTime: transcriptsList.length ? transcriptsList[transcriptsList.length - 1].endTime : 0,
                    endTime: parseFloat((performance.now() - startTime) / 1000),
                    speaker: "0",
                    transcript: transcript,
                }]
        }

        let updatedDuration = (performance.now() - startTime) / 1000;
        const { data } = await Api.post('/transcription/live/update', {
            transcription: newList,
            duration: updatedDuration,
            title: transcriptionConfig.transcriptionTitle,
            is_medical: transcriptionConfig.isMedical,
            language: transcriptionConfig.language,
        })
        setId(data.id)
        clearTimeout(streamingTimeout);
        setStreamingTimeout(null);
        setIsRecording(false);
        record.stopRecording()
        transcribeSocket.close()
        const user = await Api.get('/users/me');
        setCurrentusage(user.data.minutes_used)
        setTotalMinutes(user.data.total_allocated_minutes)
        // setLimitExceeded((user.data.minutes_used + updatedDuration) < (user.data.total_allocated_minutes*60))
        setLimitExceeded(user.data.minutes_used < (user.data.total_allocated_minutes * 60))
    }

    const sendMixPanelEvent = (event) => {
        const payload = {
            "event": event,
            "user_id": user.email,
            "properties": {
                origin: "Transcription Page"
            },
        }
        try {
            Api.post("/metrics", payload)
            console.log("metrics payload: ", payload);
        } catch (error) {
            console.log(error);
        }
    }

    const startRecoding = async () => {
        try {
            if (!isRecording) {
                setTranscriptsList([])
                setTranscript("")
                record.on('record-start', () => {
                    record.startMic().then(async (stream) => {
                        // eslint-disable next-line
                        micStream = new mic();
                        micStream.on("format", function (data) {
                            inputSampleRate = data.sampleRate;
                            console.log("inputSampleRate: ", inputSampleRate)
                        });

                        micStream.setStream(stream);
                        let response = await Api.post('/transcription/live/websocket', {
                            is_medical: transcriptionConfig.isMedical,
                            language: transcriptionConfig.language,
                            title: transcriptionConfig.transcriptionTitle
                        });
                        console.log(response)


                        //open up our WebSocket connection
                        socket = new WebSocket(response.data.url);
                        sendMixPanelEvent(MIXPANEL_EVENTS.LIVE_TRANSCRIPTION_CREATED);
                        // socket = new WebSocket("wss://app.conversesmartly.com/api/transcription");
                        socket.binaryType = "arraybuffer";
                        const available_seconds = (user.total_allocated_minutes * 60) - user.minutes_used

                        const _streamingTimeout = setTimeout(() => {
                            socket.close()
                            setIsRecording(false)
                            console.log('Timed out')
                            Api.post('/transcription/live/update', {
                                transcription: transcriptsList,
                                duration: parseInt((performance.now() - startTime) / 1000),
                                title: transcriptionConfig.transcriptionTitle,
                                is_medical: transcriptionConfig.isMedical,
                                language: transcriptionConfig.language,
                            })
                        }, available_seconds * 1000)
                        setStreamingTimeout(_streamingTimeout)
                        console.log("Socket connection established")
                        setTranscribeSocket(socket)
                        setIsRecording(true)

                        let sampleRate = 0;

                        // when we get audio data from the mic, send it to the WebSocket if possible
                        socket.onopen = () => {
                            setStartTime(performance.now())
                            micStream.on('data', (rawAudioChunk) => {
                                // the audio stream is raw audio bytes. Transcribe expects PCM with additional metadata, encoded as binary
                                let binary = convertAudioToBinaryMessage(rawAudioChunk);

                                if (socket.readyState === socket.OPEN)
                                    socket.send(binary);
                            })
                        };

                        // handle messages, errors, and close events
                        wireSocketEvents(socket);
                    })
                })
                record.startRecording().then(() => {

                })
                // navigator.mediaDevices
                //     .getUserMedia({ audio: true })
                //     .then(async (stream) => {
                //         // eslint-disable next-line
                //         micStream = new mic();
                //         micStream.on("format", function (data) {
                //             inputSampleRate = data.sampleRate;
                //         });
                //
                //         micStream.setStream(stream);
                //         let response = await Api.post('/transcription/live/websocket', {
                //             is_medical: transcriptionConfig.isMedical,
                //             language: transcriptionConfig.language,
                //             title: transcriptionConfig.transcriptionTitle
                //         });
                //         console.log(response)
                //
                //
                //         //open up our WebSocket connection
                //         socket = new WebSocket(response.data.url);
                //         // socket = new WebSocket("wss://app.conversesmartly.com/api/transcription");
                //         socket.binaryType = "arraybuffer";
                //         const available_seconds = (user.total_allocated_minutes * 60) - user.minutes_used
                //
                //         const _streamingTimeout = setTimeout(() => {
                //             socket.close()
                //             setIsRecording(false)
                //             console.log('Timed out')
                //             Api.post('/transcription/live/update', {
                //                 transcription: transcriptsList,
                //                 duration: parseInt((performance.now() - startTime) / 1000),
                //                 title: transcriptionConfig.transcriptionTitle,
                //                 is_medical: transcriptionConfig.isMedical,
                //                 language: transcriptionConfig.language,
                //             })
                //         }, available_seconds * 1000)
                //         setStreamingTimeout(_streamingTimeout)
                //         console.log("Socket connection established")
                //         setTranscribeSocket(socket)
                //         setIsRecording(true)
                //
                //         let sampleRate = 0;
                //
                //         // when we get audio data from the mic, send it to the WebSocket if possible
                //         socket.onopen = () => {
                //             setStartTime(performance.now())
                //             micStream.on('data', (rawAudioChunk) => {
                //                 // the audio stream is raw audio bytes. Transcribe expects PCM with additional metadata, encoded as binary
                //                 let binary = convertAudioToBinaryMessage(rawAudioChunk);
                //
                //                 if (socket.readyState === socket.OPEN)
                //                     socket.send(binary);
                //             })
                //         };
                //
                //         // handle messages, errors, and close events
                //         wireSocketEvents(socket);
                //     })
                //     .catch((err) => {
                //         console.log('Uh oh... unable to get stream...', err)
                //         Utils.createNotification('Connection Error! Please try again later', 'error')
                //     });
            } else {

                await closeSocket()

            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                if (id) {
                    setHideLiveRecording(true)
                    const response = await Api.get(`/transcription/${id}`);
                    setFilename(response.data.file_name);
                    setTranscriptionDate(new Date(response.data.date).toGMTString().slice(0, 17))
                    setLanguage(response.data.language)
                    if (response.data.type !== 'live') {
                        setTranscriptsList(response.data.transcription.map(items => {
                            const list = {};
                            Object.entries(items).forEach((item) => {
                                list.speaker = item[0]
                                list.transcript = item[1]
                            })

                            return list;
                        }))
                        if (response.data.entities) {
                            setEntities(response.data.entities)
                        }
                        if (response.data.sentiments) {
                            setSentiment(response.data.sentiments)
                        }
                        if (response.data.words) {
                            setWords(response.data.words)
                        }
                        if (response.data.summary) {
                            setSummary(response.data.summary)
                        }
                        setDictionary(response.data.dictionary === "Standard" ? "General" : response.data.dictionary)


                    } else {
                        setDictionary(response.data.dictionary === "Standard" ? "General" : response.data.dictionary)
                        setTranscriptsList(response.data.transcription)
                    }
                } else {
                    const {
                        language,
                        isMedical,
                        transcriptionTitle
                    } = state;

                    if (transcriptionTitle) {
                        setLanguage(
                            LIVE_TRANSCRIPTION_SUPPORTED_LANGUAGES
                                .find(o => o.value === language).label
                        )
                        setDictionary(isMedical ? "Medical" : "General")
                        setHideLiveRecording(false)
                        setFilename(transcriptionTitle)
                        setTranscriptionConfig({
                            language,
                            isMedical,
                            transcriptionTitle
                        })
                    } else {
                        setHideLiveRecording(true)
                    }
                }
                const user = await Api.get('/users/me');
                setCurrentusage(user.data.minutes_used)
                setTotalMinutes(user.data.total_allocated_minutes)
                setLimitExceeded(user.data.minutes_used < (user.data.total_allocated_minutes * 60))
            } catch (error) {
                console.log(error);
            }
        }
        fetchData();

        if (!wavesurfer && !id) {
            console.log("initializing WaveForm")
            // Create an instance of WaveSurfer
            wavesurfer = WaveSurfer.create({
                autoCenter: true,
                container: '#mic',
                waveColor: '#4535cd',
                progressColor: 'rgb(100, 0, 100)',
                sampleRate,
                // Set a bar width
                barWidth: 2,
                // Optionally, specify the spacing between bars
                barGap: 1,
                // And the bar radius
                barRadius: 2,
            })

            // Initialize the Record plugin
            record = wavesurfer.registerPlugin(RecordPlugin.create())
        }

        // TODO: close socket connection if not closed yet
        return () => {
            console.log("Destructor called")
            // debugger
            // closeSocket()
            if (transcribeSocket) {
                console.warn("Socket connection closed abruptly!")
            }
            if (record?.isRecording()) {
                record.stopRecording()
            }
            record?.destroy()
            wavesurfer?.destroy()
            wavesurfer = null
        }
    }, [])

    const entitiesColumn = [
        {
            title: "Text",
            dataIndex: 'Text'
        },
        {
            title: "Type",
            dataIndex: 'Type'
        },
    ];

    const downloadTranscription = async () => {
        if (id) {
            const response = await Api.get(`/transcription/download/${id}`, {
                responseType: 'blob'
            })
            console.log(response)
            const contentDisposition = response.headers['content-disposition'];
            console.log(contentDisposition)

            // Create a blob from the response data.
            const blob = new Blob([response.data]);
            // Create a URL for the blob content.
            const url = window.URL.createObjectURL(blob);

            // Create an anchor element to trigger the download.
            const a = document.createElement('a');
            a.href = url;
            a.download = `${filename}.txt`;
            document.body.appendChild(a);

            // Click the anchor to start the download.
            a.click();
            sendMixPanelEvent(MIXPANEL_EVENTS.TRANSCRIPTION_DOWNLOADED)

            // Remove the anchor element.
            document.body.removeChild(a);
        }
    }

    return (
        <>
            <Row>
                <Col span={20} offset={2} className='inner-wrapper audio-data-info'>
                    <Row justify={'space-between'}>
                        <Col>
                            <h3>{filename ? `${filename.substr(0, 40)}${filename.length > 39 ? '...' : ''}` : "Recording001"}
                                {/* TODO: Add filename Update functionality here */}
                                {/* <span class="icon icon-pencil" /> */}
                            </h3>
                        </Col>
                        <Col>
                            <Row>
                                {!!id && (
                                  <Button type="primary" onClick={downloadTranscription} icon={<DownloadOutlined />}>Download</Button>
                                ) }
                                <Col><h4> <span className="icon icon-calendar"></span>{transcriptionDate}</h4></Col>
                                <Col><h4> <span className="icon icon-language"></span>{language}</h4></Col>
                                <Col><h4> <span className="icon icon-book"></span> {dictionary}</h4></Col>
                            </Row>
                        </Col>
                    </Row>
                    {/*<Row>
                        {!hideLiveRecording && (
                            <Button onClick={startRecoding} disabled={!limitExceeded}>
                                {!isRecording ? 'Start Recording' : 'Stop Recording'}
                            </Button>
                        )}
                    </Row>*/}
                </Col>
            </Row>
            {!id && (
              <Row style={{ margin: '32px 0', }}>
                  <Col span={20} offset={2} className='inner-wrapper audio-data-info'>
                      {/*<div id="mic"></div>*/}
                      <WaveformContainer>
                          &nbsp; &nbsp;
                          <PlayButton
                            className="record-button"
                            onClick={startRecoding}
                            disabled={false}
                          >
                              <span className="icon icon-microphone"></span>
                              {!isRecording ? 'Record' : 'Stop'}
                          </PlayButton>
                          <Wave id='mic' />
                      </WaveformContainer>
                  </Col>
              </Row>
            )}
            <Row style={{ margin: '32px 0', }}>

                <Col span={20} offset={2} style={{ background: colorBgContainer }} className='inner-wrapper tabs-wrapper '>
                    <Tabs defaultActiveKey="1" className='tab-with-icon'>
                        <TabPane tab={<span><span className="icon icon-file-lines"></span> Full Transcript</span>} key="full-transcript">
                            {!!isRecording && (
                                <div className='speaker-recorded-list'>
                                    <span><strong>Transcribing</strong></span>
                                    {!transcript && (
                                      <Skeleton.Input active size="small" block="true" />
                                    )}
                                    {!!transcript && (
                                      <p>{transcript}</p>
                                    )}
                                </div>
                            )}
                            {transcriptsList && transcriptsList.map(item => (
                                <div className='speaker-recorded-list'>
                                    <span>
                                        <strong>Speaker {parseInt(item.speaker) + 1}</strong>
                                        {!!item.startTime && (
                                            <span> {parseFloat(item.startTime).toFixed(2)} s - {parseFloat(item.endTime).toFixed(2)} s</span>
                                        )}
                                    </span>
                                    <p>{item.transcript}</p>
                                </div>
                            ))}
                        </TabPane>
                        <TabPane tab={<span><span className="icon icon-square-list"></span>Top Keywords</span>} key="top-keywords">
                            <p>Feature Coming Soon!</p>
                        </TabPane>
                        <TabPane tab={<span><span className="icon icon-memo-circle-info"></span>Summary</span>} key="summary11">
                            {!summary && (
                              <p>Feature Coming Soon!</p>
                            )}
                            {!!summary && (
                              <p>{summary}</p>
                            )}

                        </TabPane>
                        <TabPane tab={<span><span className="icon icon-bullseye"></span>Insights</span>} key="insights">
                            {!!sentiment && (
                                <>
                                    <h1>Sentiment: {sentiment.Sentiment ? sentiment.Sentiment : sentiment}</h1>
                                    {!!sentiment.Sentiment && (
                                        <div style={{ maxWidth: 500 }}>
                                            Positive<Progress percent={sentiment?.SentimentScore?.Positive.toFixed(2) * 100} size="small" />
                                            Negative<Progress percent={sentiment?.SentimentScore?.Negative.toFixed(2) * 100} size="small" />
                                            Neutral<Progress percent={sentiment?.SentimentScore?.Neutral.toFixed(2) * 100} size="small" />
                                            Mixed<Progress percent={sentiment?.SentimentScore?.Mixed.toFixed(2) * 100} size="small" />
                                        </div>
                                    )}
                                </>
                            )}

                            <h1>Entities</h1>
                            <Table columns={entitiesColumn} dataSource={entities} />

                        </TabPane>
                        <TabPane tab={<span><span class="icon icon-clock"></span>Word Timings</span>} key="word-timings">
                            <p>Feature Coming Soon!</p>
                            {/* <p>Hover over the text to view word timings</p>
                                        {!!words && words.map(word => (
                                            <React.Fragment key={word.end_time}>
                                                <Word word={word.word} startTime={word.start_time} endTime={word.end_time} />
                                            </React.Fragment>
                                        ))} */}
                        </TabPane>
                    </Tabs>

                </Col>
            </Row>
        </>
    )
}

export default Transcribe