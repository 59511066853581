import React, { useState, useEffect } from 'react';
import { Table, Input, Dropdown, Space, Row, Col, Modal, Badge } from 'antd';
import {
    EditOutlined, DeleteOutlined, ExclamationCircleFilled,
} from '@ant-design/icons';
import Api from '../interceptor/api';
import { Link } from 'react-router-dom';
import querySting from 'query-string'
import {ALL_SUPPORTED_LANGUAGES} from "../config/constants";

const { confirm } = Modal;

function fmtMSS(s) { return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s }

const items = (item, openModal, deleteTranscription) => [
    {
        key: '1',
        label: <Space direction='horizontal' size={8}> <EditOutlined /> Rename</Space>,
        onClick: async (e) => {
            console.log({e, item})
            openModal(true, item)
            // const r = await Api.delete(`/transcription/${item.transcription_id}`)
            // console.log(r)
        }
    },
    {
        key: '2',
        label: <Space direction='horizontal' size={8}>   <DeleteOutlined />  Discard </Space>,
        onClick: async (e) => {
            console.log({ e, item })
            deleteTranscription(item.transcription_id)
        }
    },
];
const columns = (openModal, deleteTranscription) => [

    {
        title: 'Title',
        dataIndex: 'title',
        sortKey: 'file_name',
        sorter: (a, b) => {
            return a.title.localeCompare(b.title)
        },
        // sortOrder: "ascend",
        render: (_, item) => (
            <Link to={`/transcribe/${item.transcription_id}`}>{item.title}</Link>
        )
    },
    {
        title: 'Duration',
        dataIndex: 'duration',
        sortKey: 'duration',
        sorter: (a, b) => {

        },
        render: (_, item) => (
            <span>{fmtMSS(item.duration)} min</span>
        )
    },
    {
        title: 'Language',
        dataIndex: 'language',
        sortKey: 'language',

        filters: ALL_SUPPORTED_LANGUAGES.map(({ label }) => ({
            text: label,
            value: label,
        })),
        filterMultiple: false,
        filterSearch: true,
        onFilter: (value, record) => record.language.startsWith(value),
    },
    {
        title: 'Dictionary',
        dataIndex: 'dictionary',
        sortKey: 'dictionary',
        filters: [
            {
                text: 'General',
                value: 'Standard',
            },
            {
                text: 'Medical',
                value: 'Medical',
            },

        ],
        filterMode: 'tree',
        filterSearch: true,
        // onFilter: (value, record) => {
        //     console.log(value, record)
        //     return true
        // },
    },
    {
        title: 'Date',
        dataIndex: 'date',
        sortKey: 'created_at',
        sorter: (a, b) => {
            console.log('Helooooooooooooooooooooooooooooooooooooooooooooooooooo');
            console.log(a.duration, b);
            return b.duration - a.duration;
        },
        // Rules:
        // number of usages
        // expiration
    },
    {
        title: 'Type',
        dataIndex: 'type',
        sortKey: 'type',
        defaultSortOrder: 'descend',
        onFilter: (value, record) => record.dictionary.startsWith(value),
    },
    {
        title: 'Status',
        dataIndex: 'status',
        sortKey: 'status',
        defaultSortOrder: 'descend',
        onFilter: (value, record) => record.dictionary.startsWith(value),
        render: (_, item) => {
            let color = ""
            if (item.status === "created") {
                color = "blue"
            } else if (item.status === "complete") {
                color = "#52c41a"
            }
            return (
              <Badge count={item.status} color={color} />
            )
        }
    },
    {
        title: 'Action',
        dataIndex: 'operation',
        key: 'operation',
        render: (_, item) => (
            <Space size="middle">

                <Dropdown
                    menu={{
                        items: items(item, openModal, deleteTranscription),
                    }}
                >
                    <a>
                        ...
                    </a>
                </Dropdown>
            </Space>
        ),
    },
];

const testData = [
    {
        key: 1,
        "_id": 1,
        "file_name": "SampleFile1",
        "duration": 120,
        "language": "English",
        "dictionary": "Standard",
        "date": "2023-09-12",
        "status": "Completed",
        "type": "Audio",
        "transcription_id": "ABC123"
    },
    {
        key:2,
        "_id": 2,
        "file_name": "SampleFile2",
        "duration": 90,
        "language": "Spanish",
        "dictionary": "Custom",
        "date": "2023-09-13",
        "status": "In Progress",
        "type": "Video",
        "transcription_id": "XYZ789"
    }
]

const Transcriptions = () => {

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [open, setOpen] = useState(false);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [listData, setListData] = useState([]);
    const [renameInput, setRenameInput] = useState('');
    const [renameTranscriptionId, setRenameTranscriptionId] = useState('');
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({
        "status": null,
        "duration": null,
        "language": null,
        "dictionary": null,
        "type": null
    })
    const [sort, setSort] = useState({
        field: null,
        order: null,
    });
    const [tableParams, setTableParams] = useState({
        pagination: {
            current: 1,
            pageSize: 10,
        },
    });
    const showModal = () => {
        setOpen(true);
    };
    const handleOk = async () => {
        // setModalText('The modal will be closed after two seconds');
        setConfirmLoading(true);
        const r = await Api.patch(`/transcription/${renameTranscriptionId}`, {
            title: renameInput
        });
        await fetchData()
        console.log(r);
        setOpen(false);
        setConfirmLoading(false);
    };
    const handleCancel = () => {
        console.log('Clicked cancel button');
        setOpen(false);
    };
    const start = () => {
        setLoading(true);
        // ajax request after empty completing
        setTimeout(() => {
            setSelectedRowKeys([]);
            setLoading(false);
        }, 1000);
    };
    const onSelectChange = (newSelectedRowKeys) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys);
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;
    const { Search } = Input;
    const [sortedInfo, setSortedInfo] = useState(null);

    const handleChange = (pagination, filters, sorter) => {
        setSortedInfo(sorter);
    };

    async function fetchData() {
        setConfirmLoading(true);
        try {
            const query = querySting.stringify({
                ...filters,
                page_size: tableParams.pagination.pageSize,
                page_number: tableParams.pagination.current,
                sort_by: sort.field,
                sort_order: sort.order
            })
            const response = await Api.get(`/transcription/transcriptions-list?${query}`);
            console.log(response);
            const data = [];
            response.data.transcription_list.forEach((item, i) => {
                data.push({
                    key: i,
                    title: item.file_name,
                    duration: item.duration,
                    language: item.language,
                    dictionary: item.dictionary === "Standard" ? "General" : item.dictionary,
                    date: item.date,
                    status: item.status,
                    type: item.type,
                    transcription_id: item._id
                });
            })
            // data.reverse();
            setListData(data)
            setTableParams({
                ...tableParams,
                pagination: {
                    ...tableParams.pagination,
                    total: response.data.total,
                },
            });
            setConfirmLoading(false);
        } catch (error) {
            setConfirmLoading(false);
            console.log(error);
        }
    }

    useEffect(() => {
        fetchData();
        console.log(querySting.stringify({foo:123, bar:null, l: ['a,b']}))
    }, [JSON.stringify(tableParams), JSON.stringify(sort), JSON.stringify(filters)])

    const openModal = (flag, item) => {
        showModal();
        setRenameTranscriptionId(item.transcription_id)
        setRenameInput('')
    }

    const deleteTranscription = (id) => {
        confirm({
          title: 'Do you want to delete these items?',
          icon: <ExclamationCircleFilled />,
          content: 'When clicked the OK button, this dialog will be closed after 1 second',
          onOk() {
            return Api.delete(`/transcription/${id}`)
            .then(() => fetchData())
            .catch(() => console.log('unable to delete!'));
          },
          onCancel() {},
        });
      };

    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
        if (tableParams.pagination.current !== pagination.current){
            setTableParams({
                pagination
            })
        }

        if (sorter.column) {
            if (sort.field !== sorter?.column?.sortKey) {
                setSort({
                    field: sorter.column.sortKey,
                    order: sorter.order
                })
            } else if (sort.field === sorter?.column?.sortKey) {
                setSort(prevState => ({
                    field: sorter.column.sortKey,
                    order: sorter.order // prevState.order === "ascend" ? "descend" : "ascend"
                }))
            }
        }

        setFilters(filters)
    };

    return (
        <>
            <Row style={{ margin: '32px 0', }}>
                <Col span={20} offset={2} className='get-started-wrapper inner-wrapper'>
                    <h3 className='primary-title'>Recent Transcriptions</h3>
                    {/* <Row gutter={16} style={{ marginBottom: 16, }} align="center">
                         <Col xs={24} md={12} sm={12} lg={12} >
                            <Search placeholder="input search text" style={{ width: 200 }} />
                        </Col>
                        <Col xs={24} md={12} sm={12} lg={12} >
                            {hasSelected ? `Selected ${selectedRowKeys.length} items` : ''}
                        </Col>
                    </Row> */}
                    <Table
                      onChange={onChange}
                      loading={confirmLoading}
                      rowSelection={rowSelection}
                      columns={columns(openModal, deleteTranscription)}
                      dataSource={listData}
                      pagination={tableParams.pagination}
                    />
                </Col>
            </Row>
            <Modal
                title="Rename"
                open={open}
                onOk={handleOk}
                confirmLoading={confirmLoading}
                onCancel={handleCancel}
            >   
                <p><Input value={renameInput} onChange={(e) => {setRenameInput(e.target.value)}}></Input></p>
            </Modal>
        </>
    );
};
export default Transcriptions;