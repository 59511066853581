import React from 'react';

const LandingPage = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  };

  return (
  <>
    <div>
      <section className="header-section">
        <div className="container">
          <nav className="navbar navbar-expand-lg">
            <a className="navbar-brand" href="index.html"><img src="images/logo.png" alt="" /></a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <img src="images/menu.png" alt="" />
            </button>

            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav ml-auto text-right">
                <li className="nav-item active">
                  <a className="nav-link" href="#how_its_works">How it Works <span className="sr-only">(current)</span></a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#multiple_languages">Multiple Languages</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#industries">Industries</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#pricing">Pricing</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#contactus">Contact Us</a>
                </li>


              </ul>
              <div className="form-inline my-2 my-lg-0">
                <a href="#contactus" className="btn btn-secondary my-2 my-sm-0">Free consultation</a>
              </div>
            </div>
          </nav>

          <div className="caption-section">
            <h1 className="text-white">
              Converse Smartly® <br /> <span className="font-weight-100">Speech to Text</span> Software
            </h1>
            <img className="mt-3" src="images/sep_blue.png" alt="" />
          </div>
          <p className="lead text-white mt-4">
            Convert speech to text, upload audio files and generate <br />
            editable transcripts then and there.
          </p>
          <a href="/login" className="btn btn-primary my-2 mt-2">
            Sign up Today
          </a>

        </div>
      </section>

      <section className="ai-secttion">
        {/* AI section content */}
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-lg-12 col-md-12 col-xl-6">
              <h2 className="text-primary font-weight-700 ai-heading">AI-Powered <br /><span className="font-weight-300 text-black">Automated Speech <br /> to Text</span>
                Conversion</h2>
              <img src="images/gray-sep.png" alt="" />
              <p className="mt-4 mb-5 pr-5">Converse Smartly’s AI-powered engine automatically transcribes audio
                files into editable text with top keywords, summary, speaker detection,
                and sentiment analysis while highlighting themes and topics.</p>
              <a href="#contactus" className="btn btn-primary">Try Converse Smartly Today</a>


            </div>
            <div className="col-sm-12 col-lg-12 col-md-12 col-xl-6 ai-info-boxes-wrapper">

              <div className="row mb-6">
                <div className="col-sm-12 col-lg-6 col-md-12 col-xl-6">
                  <div className="ai-info-box">
                    <div className="icon-wrap">
                      <img src="images/speech_analysis.png" alt="Speech Analysis" />
                    </div>
                    <h3>
                      Speech <span className="font-weight-200">Analysis</span>
                    </h3>
                    <img className="mt-2" src="images/center_sep.png" alt="" />
                    <p className="mt-3">
                      Analyzes the voice and speech patterns in the audio.
                    </p>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-6 col-md-12 col-xl-6">
                  <div className="ai-info-box">
                    <div className="icon-wrap">
                      <img src="images/text_analysis.png" alt="" />
                    </div>
                    <h3>
                      Text <br /> <span className="font-weight-200">Analysis</span>
                    </h3>
                    <img className="mt-2" src="images/center_sep.png" alt="Text Analysis" />
                    <p className="mt-3">
                      Find insights, trends, or patterns in the converted text.
                    </p>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12 col-lg-6 col-md-12 col-xl-6">
                  <div className="ai-info-box">
                    <div className="icon-wrap">
                      <img src="images/multiple_speaker.png" alt="Multiple Speaker Detection" />
                    </div>
                    <h3>
                      Multiple Speaker <span className="font-weight-200">Detection</span>
                    </h3>
                    <img className="mt-2" src="images/center_sep.png" alt="" />
                    <p className="mt-3">
                      Identify multiple speakers and tag them easily.
                    </p>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-6 col-md-12 col-xl-6">
                  <div className="ai-info-box">
                    <div className="icon-wrap">
                      <img src="images/live_audio.png" alt="Live Audio Transcription" />
                    </div>
                    <h3>
                      Live <br /> Audio <br /> <span className="font-weight-200">Transcription</span>
                    </h3>
                    <img className="mt-2" src="images/center_sep.png" alt="" />
                    <p className="mt-3">
                      Auto transcribe live audio from English in real-time.
                    </p>
                  </div>
                </div>
              </div>


            </div>

          </div>
        </div>
      </section>

      <section className="converse-smartly" id="how_its_works">
        {/* Converse Smartly section content */}
        <div className="container">
          <div className="text-center">
            <h2 className="text-white font-weight-700">How <span className="font-weight-200">Converse Smartly</span> Works?</h2>
            <img src="images/gray-sep.png" alt="" />
          </div>


          <div className="row circle-box-wrap text-center">
            <img className="img-fluid m-auto" src="images/converse-smartly-img.png" alt="" />
          </div>

          <div className="text-center">
            <a href="#contactus" className="btn btn-secondary">Sign up Today</a>
          </div>


        </div>

      </section>

      <section className="better-transcription">
        {/* Better Transcription section content */}
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="text-center">
                <h2 className="font-weight-700"><span className="font-weight-200">Better Transcription</span> with AI</h2>
                <img className="mt-3" src="images/gray-sep.png" alt="" />
              </div>
            </div>
          </div>
          <div className="row transcription-box-wrap">
            <div className="col-sm-4">
              <div className="transcription-info-box text-center">
                <img src="images/sentiment_analysis.png" alt="Sentiment Analysis" />
                <h3><span className="font-weight-700">Sentiment</span> Analysis</h3>
                <p>Identify the sentiment of a speaker <br /> with your transcripted text.</p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="transcription-info-box text-center">
                <img src="images/crux.png" alt="Get to the Crux" />
                <h3><span className="font-weight-700">Get to the </span> Crux</h3>
                <p>Mark and find the words that you’re <br /> looking for and get right into it.</p>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="transcription-info-box text-center">
                <img src="images/identify.png" alt="Identify Trends" />
                <h3><span className="font-weight-700">Identify</span> Trends</h3>
                <p>Identify key patterns in <br />conversations like the number of <br /> times something was said.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="transcription-section" id="multiple_languages">
        {/* Transcription for Multiple Languages section content */}
        <div className="row mr-0 ml-0">
          <div className="col-xl-5 col-lg-12">
            <h2>
              <span className="font-weight-200">Transcription for a</span> <span className="text-primary font-weight-800"> Range of Languages</span>
            </h2>
            <img className="mt-3 mb-3" src="images/gray-sep.png" alt="" />
            <p>Converse Smartly can transcribe text for a number of different languages.</p>

            <div className="flag-wrap">
              <ul>
                <li>
                  <span className="flag-img">
                    <img src="images/english_flag.png" alt="English" />
                  </span>
                  <span>
                    English
                  </span>
                </li>
                <li>
                  <span className="flag-img">
                    <img src="images/germany.png" alt="Germany" />
                  </span>
                  <span>
                    Germany
                  </span>
                </li>
                <li>
                  <span className="flag-img">
                    <img src="images/arabic.png" alt="Arabic" />
                  </span>
                  <span>
                    Arabic
                  </span>
                </li>
                <li className="pl-5">
                  <span className="flag-img">
                    <img src="images/spanish.png" alt="Spanish" />
                  </span>
                  <span>
                    Spanish
                  </span>
                </li>
                <li>
                  <span className="flag-img">
                    <img src="images/french.png" alt="French" />
                  </span>
                  <span>
                    French
                  </span>
                </li>
              </ul>
            </div>

          </div>
          <div className=" pr-0 col-lg-12 col-xl-7">
            <div className="range-img-wrap">
              <img className="img-fluid w-100" src="images/transription.png" alt="Transcription for a Range of Languages" />
            </div>
          </div>
        </div>
      </section>

      <section className="automated-transcription" id="industries">
        {/* Automated Transcription section content */}
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="text-center">
                <h2 className="text-white font-weight-700">Automated <span className="font-weight-200"> Audio Transcription</span> for Everyone</h2>
                <img className="mt-4 mb-4" src="images/gray-sep.png" alt="" />
                <p>Our automated audio transcription service is designed to serve individuals and professionals from a
                  range of industries and fields. Try out Converse Smartly today!</p>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-sm-3">
              <div className="blue-box text-center">
                <img className="mb-4" width="100px" src="images/journalism.png" alt="Journalism" />
                <h4>
                  Journalism
                </h4>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="blue-box text-center">
                <img className="mb-4" width="100px" src="images/marketing.png" alt="Marketing" />
                <h4>
                  Marketing
                </h4>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="blue-box text-center">
                <img className="mb-4" width="100px" src="images/education.png" alt="Education" />
                <h4>
                  Education
                </h4>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="blue-box text-center">
                <img className="mb-4" width="100px" src="images/research.png" alt="Research" />
                <h4>
                  Research
                </h4>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-sm-3">
              <div className="blue-box text-center">
                <img className="mb-4" width="100px" src="images/government.png" alt="Government" />
                <h4>
                  Government
                </h4>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="blue-box text-center">
                <img className="mb-4" width="100px" src="images/media.png" alt="Media" />
                <h4>
                  Media
                </h4>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="blue-box text-center">
                <img className="mb-4" width="100px" src="images/law.png" alt="Law" />
                <h4>
                  Law
                </h4>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="blue-box text-center">
                <img className="mb-4" width="100px" src="images/healthcare.png" alt="Healthcare" />
                <h4>
                  Healthcare
                </h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 pt-3">
              <div className="text-center pt-5">
                <a href="#contactus" className="btn btn-secondary">Try Converse Smartly for Free</a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="pricing-section" id="pricing">
        {/* Pricing section content */}
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="text-center">
                <h2><span className="font-weight-200">Pricing</span> <span className="font-weight-800 text-primary">for Converse Smartly</span></h2>
                <img className="mt-4 mb-4" src="images/gray-sep.png" alt="" />
                  <p>
                    Reach out to us with your requirements and get a quote for a customized deployment.
                  </p>
              </div>
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-lg-12 col-xl-2"></div>
            <div className="col-lg-6 col-xl-4">
              <div className="pricing-box">
                <h2>
                  <span className="text-primary">$12.99/hour</span>
                  <br />
                    <span>Monthly</span>
                </h2>
                <p>(Fair usage of 3 hours)</p>
              </div>
            </div>
            <div className="col-lg-6 col-xl-4">
              <div className="pricing-box">
                <h2>
                  <span className="text-primary">$5.99/hour</span>
                  <br />
                    <span>Hourly</span>
                </h2>
              </div>
            </div>
            <div className="col-lg-12 col-xl-2"></div>
          </div>
        </div>
      </section>

      <section className="enterprise-section">
        {/* Enterprise section content */}
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="text-center">
                <h2 className="text-white"><span className="font-weight-700">Converse Smartly for</span> <span className="font-weight-200">Enterprise</span></h2>
                <img className="mt-4 mb-4" src="images/gray-sep.png" alt="" />
                  <p className="text-white lead">
                    Looking for a robust automated transcription platform that caters to the needs of your organization? <br />
                  </p>
                  <p className="lead text-white">
                    Converse Smartly helps enterprises boost productivity and get more work done.<br />
                      Reach out to us to learn more about Converse Smartly for Enterprise.
                  </p>
                  <div className="text-center m-5">
                    <a href="#contactus" className="btn btn-secondary mt-2">Get in Touch</a>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="contact-section" id="contactus">
        {/* Contact section content */}
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="text-center">
                <h2 className="text-primary text-uppercase"><span className="font-weight-700">LET’S TALK ABOUT</span> <span className="font-weight-200 text-black">YOUR PROJECT</span></h2>
                <img className="mt-4 mb-4" src="images/gray-sep.png" alt="" />
              </div>
            </div>
          </div>

          <div className="row mt-4">
            <div className="col-sm-12">
              <div className="custom-form">
                <div className="form-row">
                  <div className="form-group col-md-4">
                    <input type="text" className="form-control" id="" placeholder="Your Name" />
                  </div>
                  <div className="form-group col-md-4">
                    <input type="email" className="form-control" id="" placeholder="Email" />
                  </div>
                  <div className="form-group col-md-4">
                    <input type="number" className="form-control" id="" placeholder="Phone" />
                  </div>
                </div>
                <div className="form-row">
                  <div className="form-group  col-md-12">
                    <textarea className="form-control" id="" placeholder="Message" rows="3"></textarea>
                  </div>
                </div>
                <div className="text-center mt-4">
                  <button type="submit" className="btn btn-primary">Get in Touch</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <footer>
        {/* Footer content */}
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h5 className="text-uppercase">Navigation</h5>
              <img src="images/seprator_white.png" alt="" />
            </div>
          </div>
          <div className="row mt-5">
            <div className="col col-lg-2">
              <h4 className="font-weight-600">
                Company
              </h4>
              <ul className="mt-4">
                <li><a href="javascript:void(0);">Home</a></li>
                <li><a href="javascript:void(0);">service</a></li>
                <li><a href="javascript:void(0);">Solutions</a></li>
                <li><a href="javascript:void(0);">Why us</a></li>
                <li><a href="javascript:void(0);">Contact us</a></li>
              </ul>
            </div>
            <div className="col col-lg-3">
              <h4 className="font-weight-600">
                Solutions
              </h4>
              <ul className="mt-4">
                <li><a href="javascript:void(0);">Machine Learning</a></li>
                <li><a href="javascript:void(0);">Computer Vision</a></li>
                <li><a href="javascript:void(0);">Natural Language Processing</a></li>
                <li><a href="javascript:void(0);">Predictive Analytics</a></li>
                <li><a href="javascript:void(0);">Livestock Management </a></li>
                <li><a href="javascript:void(0);">Amazon Transcribe </a></li>
                <li><a href="javascript:void(0);">Azure Machine Learning </a></li>
                <li><a href="javascript:void(0);">AI Image Processing Service </a></li>
                <li><a href="javascript:void(0);">Google Speech </a></li>
                <li><a href="javascript:void(0);">IBM Watson </a></li>
                <li><a href="javascript:void(0);">Big Data Solutions </a></li>
                <li><a href="javascript:void(0);">Robotic Automation </a></li>
              </ul>
            </div>
            <div className="col-xs-12 col">
              <h4 className="font-weight-600">
                Industries
              </h4>
              <ul className="mt-4">
                <li><a href="javascript:void(0);">AI Automotive Solutions</a></li>
                <li><a href="javascript:void(0);">Fashion Tagging</a></li>
                <li><a href="javascript:void(0);">ML Security Solutions</a></li>
                <li><a href="javascript:void(0);">AI Visual Inspection Solution</a></li>
                <li><a href="javascript:void(0);">AI Automotive Solutions </a></li>
                <li><a href="javascript:void(0);">Time Series Forecasting </a></li>

              </ul>
            </div>
            <div className="col-xs-12 col">
              <h4 className="font-weight-600">
                Demo
              </h4>
              <ul className="mt-4">
                <li><a href="javascript:void(0);">Road Traffic Analysis</a></li>
                <li><a href="javascript:void(0);">Speech to text software</a></li>
                <li><a href="javascript:void(0);">Face Recognition</a></li>
              </ul>
            </div>
            <div className="col-xs-12 col">
              <h4 className="font-weight-600">
                In the news
              </h4>
              <ul className="mt-4">
                <li><a href="javascript:void(0);">Machine Learning for Ananomaly Detection</a></li>
                <li><a href="javascript:void(0);">Best NLP Examples</a></li>
                <li><a href="javascript:void(0);">Uses of Predictive Analytics</a></li>
                <li><a href="javascript:void(0);">Computer vision in manufacturing</a></li>
                <li><a href="javascript:void(0);">Machine Learning Applications in Businesses</a></li>
                <li><a href="javascript:void(0);">AI Developer Jobs </a></li>

              </ul>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-sm-12">
              <hr />
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-sm-12">
              <h5 className="text-uppercase">Location</h5>
              <img src="images/seprator_white.png" alt="" />
            </div>
          </div>

          <div className="row mt-4 address-info">
            <div className="col-sm-3">
              <h4 className="font-weight-600 mb-4">
                US office
              </h4>
              <p>
                Belmont, California – 1301 Shoreway Road,
                Suite 160, Belmont, CA 94002
              </p>
              <p>
                Pleasanton, California – 6701 Koll Center
                Parkway, #250 Pleasanton, CA 94566
              </p>
              <p>
                Tel: <a href="tel:+1 408 365 4638">+1 408 365 4638</a> <br />
                  Support: <a href="tel: +1 (408) 512 1812">+1 (408) 512 1812</a>
              </p>
            </div>
            <div className="col-sm-3">
              <h4 className="font-weight-600 mb-4">
                Pakistan Office
              </h4>
              <p>
                163 Bangalore Town, Main Shahrah-e-Faisal,
                Karachi – 75350
              </p>
              <p>
                705, Business Center, PECHS Block-6,
                Shahrah-e-Faisal, Karachi – 75350
              </p>
              <p>
                First Floor, Blue Mall 8-R, MM Alam Road
                Gulberg III, Lahore
              </p>
            </div>
            <div className="col-sm-3">
              <h4 className="font-weight-600 mb-4">
                UAE Office
              </h4>
              <p>
                Dubai, UAE – Dubai Internet City, 1st Floor,
                Building Number 12, Premises ED 29,
                Dubai, UAE
              </p>
              <p>
                Tel: <a href="tel:+971-55-6540154 ">+ 971-55-6540154 </a> <br />
                  Support: <a href="tel: +971-04-2505173">+ 971-04-2505173</a>
              </p>

            </div>
            <div className="col-sm-3">
              <h4 className="font-weight-600 mb-4">
                Mexico Office
              </h4>
              <p>
                Amado Nervo #2200, Edificio Esfera 1
                piso 4, Col. Jardines del Sol, CP. 45050,
                Zapopan, Jalisco, Mexico
              </p>

            </div>
          </div>
          <div className="row mt-4 address-info">
            <div className="col-sm-3">
              <h4 className="font-weight-600 mb-4">
                UK Office
              </h4>
              <p>
                Export House, Cawsey Way, Woking
                Surrey, GU21 6QX
              </p>

              <p>
                Tel: <a href="tel:(148) 339-7625">(148) 339-7625</a> <br />

              </p>
            </div>
            <div className="col-sm-3">
              <h4 className="font-weight-600 mb-4">
                Canada Office
              </h4>
              <p>
                895 Don Mills Road, Two Morneau Shepell Center,
                Suite 900, Toronto, Ontario, M3c 1w3, Canada
              </p>

            </div>
            <div className="col-sm-3">
              <h4 className="font-weight-600 mb-4">
                Australia Office
              </h4>
              <p>
                C/- Prime Partners Level 4 1 James Place
                NORTH SYDNEY New South Wales 2060, Australia
              </p>



            </div>
            <div className="col-sm-3">
              <h4 className="font-weight-600 mb-4">
                Bulgaria Office
              </h4>
              <p>
                49 Bacho Kiro Street, Sofia, 1000,
                Bulgaria
              </p>

            </div>
          </div>
        </div>
      </footer>
      <div className="copy-right">
        <div className="container">
          <div className="row">
            <div className="col-sm-8">
              <p> © 2022, Folio3 Software Inc., All Rights Reserved. Privacy Policy and Terms of Use | Cookie Policy</p>
            </div>
            <div className="col-sm-4">
              <ul>
                <li><a href="javascript:void(0);"><img src="images/facebook.png" alt="" /></a></li>
                <li><a href="javascript:void(0);"><img src="images/linkedin.png" alt="" /></a></li>
                <li><a href="javascript:void(0);"><img src="images/twitter.png" alt="" /></a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* <!-- Back to top button --> */}
    <a id="scroll-button" className='show' onClick={scrollToTop} />
  </>
)}

export default LandingPage;