import React, {useEffect} from 'react';
import {Image, Row} from 'antd';
import {useNavigate} from 'react-router-dom';
import {useDispatch} from "react-redux";
import Api from "../../interceptor/api";
import {loginError,} from "../../redux/reducers/AuthReducer";
import {Utils} from "../../utils";

let path = process.env.PUBLIC_URL;
let logo = "/images/converse-smartly.svg";

const VerifyEmail = (props) => {

	const [isVerified, setIsVerified] = React.useState(false);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const getQueryParams = () => {
		const params = {};
		const decodedUrl = decodeURIComponent(window.location.search);

		if (decodedUrl) {
			decodedUrl.split('?')[1].split('&').forEach((item) => {
				const key = item.split('=')[0];
				params[key] = item.split('=')[1];
			})
		}

		return params;
	}

	const verifyUserEmail = async () => {
		const searchParams = getQueryParams();
		const token = searchParams['token'];
		const email = searchParams['email'];

		if (token && email) {
			try {
				const response = await Api.get(`/auth/verify-email?token=${encodeURIComponent(token)}&email=${encodeURIComponent(email)}`);
				const data = response.data;
				console.log(response);
				setIsVerified(true);
				if (response.status === 200) {
					Utils.createNotification(`Email Verified`, "success");
					navigate("/login");
					console.log({ data });
				} else {
					console.log({ response });
					Utils.createNotification(`Error! Something went wrong`, "error");
				}
			} catch (err) {
				dispatch(loginError(err.response.data.detail));
			}
		}
	};

	useEffect(() => {
		verifyUserEmail();
	}, []);

	return (
		<>
			<Row className='wrapper' type="flex" justify="center" style={{ height: "100vh" }} align="middle">
				<div className='login-wrapper'>
					<div className='login-header'>
						<Image width={280} src={path + logo} preview={false} />
						{isVerified && (
							<h1>Email Verified</h1>
						)}
					</div>

				</div>
			</Row>
		</>
	)
}

export default VerifyEmail