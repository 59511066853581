import React from "react";
import {Route, Routes, BrowserRouter as Router, Navigate} from "react-router-dom";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import Home from "./components/Home";
import NotificationContainer from "react-notifications/lib/NotificationContainer";
import ForgotPassword from "./components/auth/ForgotPassword";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import Transcribe from "./components/Transcribe";
import { logoutUser } from "./redux/reducers/AuthReducer";
import { Utils } from "./utils";
import ResetPassword from "./components/auth/ResetPassword";
import VerifyEmail from "./components/auth/VerifyEmail";
import Plans from "./components/Plans";
import ReportBug from "./components/ReportBug";
import MainLayout from "./components/MainLayout"
import LandingPage from "./components/LandingPage";

const routes = [
  {
    path: "register",
    exact: true,
    isAuth: false,
    component: Register,
  },
  {
    path: "login",
    exact: true,
    isAuth: false,
    component: Login,
  },
  {
    path: "forgot-password",
    exact: true,
    isAuth: false,
    component: ForgotPassword,
  },
  {
    path: "transcribe",
    exact: true,
    isAuth: true,
    component: Transcribe,
  },
  {
    path: "transcribe/:id",
    exact: true,
    isAuth: true,
    component: Transcribe,
  },
  {
    path: "reset-password",
    exact: true,
    isAuth: false,
    component: ResetPassword,
  },
  {
    path: "verify-email",
    exact: true,
    isAuth: false,
    component: VerifyEmail,
  },
  {
    path: "plans",
    exact: true,
    isAuth: true,
    component: Plans,
  },
  {
    path: "report-bug",
    exact: true,
    isAuth: true,
    component: ReportBug,
  },
  {
    path: "",
    exact: true,
    isAuth: true,
    component: Home,
  },
  {
    path: "",
    exact: true,
    isAuth: false,
    component: LandingPage,
  }
];

const AppRoutes = () => {
  const userDetails = useSelector((state) => state.AuthReducer.user);
  const dispatch = useDispatch();

  const logout = async () => {
    try {
      localStorage.clear();
      dispatch(logoutUser());
      Utils.createNotification("User successfully Logged out!", 'success')
    } catch (e) {
      console.log(`Axios request failed: ${e}`);
    }
  };

  return (
    <Router>
      <div className="mainView">
        <Routes>
          {!!userDetails && (
            <Route
              exact={true}
              path={"/"}
              element={<MainLayout logout={logout} />}
            >
              {/********** Private Routes ****************/}
              {routes.map(
                (route, index) =>
                  route.isAuth &&
                  !_.isNull(userDetails) && (
                    <Route
                      key={index}
                      path={route.path}
                      exact={route.exact}
                      element={<>
                        <route.component
                          userStatus={userDetails}
                          logout={logout}
                        />
                      </>}
                    />
                  )
              )}
            </Route>
          )}
          {!userDetails && (<>
              <Route
                exact={true}
                path={"/"}
                element={<><LandingPage
                  userStatus={userDetails}
                /></>}
              />

              {/********** Public Routes ****************/}
              {routes.map(
                (route, index) =>
                  !route.isAuth &&
                  _.isNull(userDetails) && (
                    <Route
                      key={index}
                      path={route.path}
                      element={<route.component/>}
                    />
                  )
              )}
            </>
          )}
          <Route
            path="*"
            element={<Navigate to="/" replace />}
          />
        </Routes>
      </div>
      <NotificationContainer />
    </Router>
  );
};
export default AppRoutes;
