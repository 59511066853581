import { UserOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Col, Layout, Menu, Row, theme, Avatar, Space, Dropdown, Button, Image, Progress, Breadcrumb } from 'antd';
import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Alert } from "antd";

import Api from '../interceptor/api';
import { userUpdate } from '../redux/reducers/AuthReducer';
import { MIXPANEL_EVENTS } from '../config/constants';

const {
    Header,
    Content,
    Footer,
    Sider,
} = Layout;

let path = process.env.PUBLIC_URL;
let TranscribeIcon = path + "/images/transcribe-btn-icon.svg";
let Logout = path + "/images/logout.svg";
let Logo = path + "/images/logo.svg";
let diamond = path + "/images/diamond.svg";
let transcriptions = path + "/images/transcriptions.svg";
let business = path + "/images/buildings.svg";
let intigrations = path + "/images/layer-group.svg";
let messageExclamation = path + "/images/message-exclamation.svg";
let home = path + "/images/house.svg";

const items = (user) => [
    {
        label: user.email,
        disabled: true,
    },
    {
        type: 'divider',
    },
    {
        label: 'Logout',
        key: 'logout',
        icon: <Image src={Logout} preview={false} />,
    },
];

const sidebarMenu: MenuProps['items'] = [
    {
        type: 'group',
        label: 'My Space',
        key: 'my-space',
        children: [
            {
                label: 'Dashboard',
                icon: <Image src={home} preview={false} />,
                key: 'dashboard',
            },
            {
                label: 'My Plan',
                key: 'plans',
                icon: <Image src={business} preview={false} />,
            },
        ],
    },
    {
        type: 'divider',
        key: 'custom-menu-devider-1'
    },
    {
        type: 'group',
        label: 'Coming Soon',
        key: 'coming-soon',
        children: [],
    },
    {
        label: <span>Video Transcription</span>,
        icon: <Image src={transcriptions} preview={false} />,
        disabled: true
    },
    {
        label: 'Integrations',
        icon: <Image src={intigrations} preview={false} disabled={true} />,
        disabled: true
    },
    {
        label: 'Converse Smartly for Business',
        key: 'converse-smartly-for-business',
        icon: <Image src={business} preview={false} />,
        disabled: true
    },
    {
        type: 'divider',
        key: 'custom-menu-divider'
    },
    {
        label: 'Report a bug',
        key: 'report',
        icon: <Image src={messageExclamation} preview={false} />,
    },
];


function fmtMSS(s) { return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s }

type User = {
    minutes_used: string,
    subscription_plan: string,
    total_allocated_minutes: string,
}

const MainLayout = ({ logout, children }) => {
    const [collapsed, setCollapsed] = useState(false);
    const dispatch = useDispatch();
    const user: User = useSelector((state) => state.AuthReducer.user);
    const [currentUsage, setCurrentUsage] = useState(false);
    const [totalMinutes, setTotalMinutes] = useState(false);
    const navigate = useNavigate();
    const {
        token: { colorBgContainer },
    } = theme.useToken();

    const sendMixPanelEvent = (to) => {
        const payload = {
            "event": MIXPANEL_EVENTS.NAVIGATION,
            "user_id": user.email,
            "properties": {
                to,
                from: window.location.pathname,
                origin: "Sidebar Navigation"
            },
        }
        try {
            if (to !== window.location.pathname) {
                Api.post("/metrics", payload)
                console.log("metrics payload: ", payload);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const onMenuItemClick = (e) => {
        switch (e.key) {
            case 'transcription':
                sendMixPanelEvent('/transcribe')
                navigate('/transcribe')
                break;
            case 'plans':
                sendMixPanelEvent('/plans')
                navigate('/plans')
                break;
            case 'report':
                sendMixPanelEvent('/report-bug')
                navigate('/report-bug')
                break;
            case 'dashboard':
                sendMixPanelEvent('/')
                navigate('/')
                break;
            default:
                break;
        }
    }

    const onDropdownClick = (e) => {
        if (e.key === 'logout') {
            logout();
        }
    }

    useEffect(() => {
        const init = async () => {
            try {
                const user = await Api.get('/users/me');
                setCurrentUsage(user.data.minutes_used)
                setTotalMinutes(user.data.total_allocated_minutes)
                console.log('MainLayout Component Mounted, User:', user.data)
                dispatch(userUpdate(user.data))
            } catch (error) {
                console.log(error);
            }
        }

        init();
    }, [])

    const calculateCurrentUsage = () => {
        return <h1>{fmtMSS(currentUsage)}/{fmtMSS(totalMinutes * 60)}</h1>
    }

    return (
        <Layout className="site-layout">
            <Sider trigger={null} collapsible collapsed={collapsed} className='left-sidebar' style={{
                overflow: 'auto',
                height: '100vh',
                position: 'fixed',
                left: 0,
                top: 0,
                bottom: 0,
            }}>
                <div className='sidebar-top'>
                    <Row align="middle" >
                        <Col><Image src={Logo} preview={false} style={{ marginBottom: '2rem' }} /></Col>
                    </Row>
                    <Menu className='main-menu' onClick={onMenuItemClick} theme="dark" defaultSelectedKeys={['dashboard']} mode="inline" items={sidebarMenu} />
                </div>
                <div className='sidebar-bottom'>
                    <div className='upgrade-plan-widget'>
                        <Row justify="space-between" align="top">
                            <Col span={18}>
                                <h3>{!!user?.subscription_plan ? (
                                    user.subscription_plan === "Free" ? "Upgrade your plan to get more minutes." : user.subscription_plan
                                ) : "Upgrade your plan to get more minutes."}</h3>
                            </Col>
                            <Col>
                                <Image src={diamond} preview={false} />
                            </Col>
                        </Row>
                        <p>Transcription Used </p>
                        <Progress percent={parseInt((currentUsage / (totalMinutes * 60)) * 100)} />
                        <span>{calculateCurrentUsage()}</span>
                        <Button
                            type="primary"
                            style={{ width: '100%' }}
                            onClick={() => {
                                navigate('/plans')
                                sendMixPanelEvent('/plans');
                            }}>
                            Upgrade Now
                        </Button>
                    </div>
                    <div className='sidebar-footer'>
                        <p>© 2023 Converse Smartly®</p>
                    </div>
                </div>
            </Sider>
            <Layout>
                {user?.subscription_plan === "Free" && (
                    <Space style={{ width: '100%' }} direction="vertical">
                        <Alert style={{ width: '100%' }} message="You are currently using the Free plan. Subscribe to get more minutes and unlock other features!" type="info" banner={true} />
                    </Space>
                )}

                <Header
                    className='top-header'
                    style={{
                        background: colorBgContainer,
                    }}
                >
                    <Row justify="space-between" align="middle">
                        <Col>
                            <Breadcrumb
                                items={[
                                    {
                                        title: 'Dashboard',
                                    },
                                ]}
                            />
                        </Col>
                        <Col>
                            <Row>
                                <Col>
                                    <Button
                                        onClick={() => {
                                            sendMixPanelEvent('/transcribe');
                                            navigate('/transcribe')
                                        }}
                                        type="primary"
                                        icon={
                                            <Image src={TranscribeIcon} preview={false} />
                                        }
                                        className='header-transcribe-btn'>
                                        Transcribe
                                    </Button>
                                </Col>
                                {/* <Col>
                                    <div className='header-setting-icon'><Image src={settingIcon} preview={false} /></div>
                                </Col> */}
                                <Col className='header-user-menu'>
                                    <Dropdown menu={{ items: items(user), onClick: onDropdownClick }} placement="bottom" trigger={['click']} overlayClassName="header-dropdown-menu">
                                        <Space size={16}>
                                            <Avatar icon={<UserOutlined />} />
                                        </Space>
                                    </Dropdown>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Header>
                <Content
                    style={{
                        margin: '32px 16px',
                    }}
                >
                    <Outlet />
                </Content>
            </Layout>
        </Layout>
    );
};
export default MainLayout;