import React from 'react';
import { MailOutlined } from '@ant-design/icons';
import { Row, Button, Form, Input, Image, Col } from 'antd';
import { useNavigate } from 'react-router-dom';
import Api from '../../interceptor/api';
import { Utils } from '../../utils';
let path = process.env.PUBLIC_URL;
let logo = "/images/converse-smartly.svg";
const ForgotPassword = () => {
    const navigate = useNavigate();
  const onFinish = async (values) => {
    console.log('Received values of form: ', values);
    try {
        const response = await Api.post('/password/forgot', {
            email: values.username
        });    
        if (response.status === 201) {
            Utils.createNotification(`Email sent to registered email`, "success");
            navigate("/login")
          } else {
            console.log({response})
            Utils.createNotification(`Error! Something went wrong`, "error");
          }
    } catch (error) {
        
    }

};
return (
    <>
        <Row className='wrapper' type="flex" justify="center" style={{ height: "100vh" }} align="middle">
            <div className='login-wrapper'>
                <div className='login-header'>
                    <Image width={280} src={path + logo} preview={false} />
                    <h1>Forgot your password?</h1>
                    <Row>
                      <Col span={16} offset={4}>
                      <p>Enter your email address and we will send you instructions to reset your password.</p>
                      </Col>
                    </Row>
                </div>
                <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="username"
                        rules={[{ required: true, message: 'Please input your Username!' }]}
                    >
                        <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email address" />
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button w-100">
                            Send
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </Row>
    </>
)
}

export default ForgotPassword