import { Col, Row, Button, } from 'antd';
import { useState } from 'react';
import Utils from '../utils';
import Api from '../interceptor/api';
import { useSelector } from 'react-redux';
import PricingCards from "./PricingCards";
import {
    PLANS,
    PAY_AS_YOU_GO_PRICE_ID,
    PERSONAL_USER_PRICE_ID,
    PROFESSIONAL_PRICE_ID,
} from '../config/constants';


const Plans = (props) => {
    const [loading, setLoading] = useState(false);
    const user = useSelector((state) => state.AuthReducer.user);

    const subscribe = (priceId, isOneTime = false) => {
        Api.post("stripe/create-checkout-session", {
            priceId: priceId,
            isOneTime
        }).then(function (result) {
            window.location.href = result.data.url;
        });
    }

    const cardsData = [
        {
            title: 'Pay as you go',
            price: '$9.99/month',
            features: ['60 minutes', 'Transcribe Audio Files', 'Transcribe Live Audio', 'View Insights of Transcriptions'],
            onClick: () => subscribe('price_1NsM8IC95bUId8wTZxCVDn7Z', true)
        },
        {
            title: 'Personal User',
            price: '$19.99/month',
            features: ['180 minutes'],
            onClick: () => subscribe('price_1NsM7lC95bUId8wT6uQ6cGYt')
        },
        {
            title: 'Professional',
            price: '$19.99/month',
            features: ['360 minutes'],
            onClick: () => subscribe('price_1NsLx8C95bUId8wTK64tlLw3')
        },
    ];

    const isSubscribed = (plan) => {
        return user.subscription_plan === plan;

    }

    const hasSubscription = () => {
        return user.subscription_plan === PLANS.PROFESSIONAL || (user.subscription_plan === PLANS.PERSONAL_USER);

    }

    const cancelSubscription = async () => {
        if (hasSubscription()) {
            try {
                setLoading(true)
                await Api.post('/transcription/cancel');
                Utils.createNotification("Request submitted. Support team is on it!", "info")
                setLoading(false)
            } catch (error) {
                console.log(error);
                setLoading(false)
                Utils.createNotification("We have encountered an issue. Please try again later", "error")
            }
        }
    }

    return (
        <>
            <h1>Plans</h1>
            <Row>
                <Col span={6} className='get-started-wrapper inner-wrapper mr-10'>
                    <div>
                        <h1>Pay as you go</h1>
                        <p>Price: $5.99 <b>One Time</b></p>
                        <p>60 minutes</p>
                        <Button onClick={() => subscribe(PAY_AS_YOU_GO_PRICE_ID, true)}>Subscribe</Button>
                    </div>
                </Col>
                <Col span={6} className='get-started-wrapper inner-wrapper mr-10'>
                    <div>
                        <h1>Personal Use</h1>
                        <p>Price: $12.99/Month</p>
                        <p>180 minutes</p>
                        <Button disabled={hasSubscription()} onClick={() => subscribe(PERSONAL_USER_PRICE_ID)}>
                            {isSubscribed(PLANS.PERSONAL_USER) ? 'Subscribed' : 'Subscribe'}
                        </Button>
                        {isSubscribed(PLANS.PERSONAL_USER) && (
                          <Button loading={loading} onClick={cancelSubscription} type="primary" danger="true" className="ml-2">
                              Cancel
                          </Button>
                        )}
                    </div>
                </Col>
                <Col span={6} className='get-started-wrapper inner-wrapper mr-10'>
                    <div>
                        <h1>Professional</h1>
                        <p>Price: $19.99/Month</p>
                        <p>360 minutes</p>
                        <Button disabled={hasSubscription()} onClick={() => subscribe(PROFESSIONAL_PRICE_ID)}>
                            {isSubscribed(PLANS.PROFESSIONAL) ? 'Subscribed' : 'Subscribe'}
                        </Button>
                        {isSubscribed(PLANS.PROFESSIONAL) && (
                          <Button loading={loading} onClick={cancelSubscription} type="primary" danger="true" className="ml-2">
                              Cancel
                          </Button>
                        )}
                    </div>
                </Col>
            </Row>
            <Row>
                {/* <PricingCards cardsData={cardsData}>

                        </PricingCards> */}
            </Row>
        </>
    );
};
export default Plans;