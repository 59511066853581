import { createSlice } from "@reduxjs/toolkit";
const _ = require("lodash");

const AuthReducer = createSlice({
  name: "AuthReducer",
  initialState: {
    loading: false,
    error: false,
    success: false,
    user: !_.isEmpty(localStorage.getItem("user"))
      ? JSON.parse(localStorage.getItem("user"))
      : null,
  },
  reducers: {
    loginSubmit: (state) => {
      state.loading = true;
    },
    loginSuccess: (state, action) => {
      console.log(state, action)
      state.loading = false;
      state.user = action.payload;
      state.success = true;
      state.error = false;
    },
    loginError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = false;
    },
    logoutUser: (state) => {
      state.user = null;
      state.error = false;
      state.success = false;
    },
    userUpdate: (state, action) => {
      console.log(state, action)
      console.log('####################################')
      state.loading = false;
      state.user = {...state.user, ...action.payload};
      state.success = true;
      state.error = false;
    }
  },
});

export const { loginSubmit, loginSuccess, loginError, logoutUser, userUpdate } = AuthReducer.actions;

export default AuthReducer.reducer;
