import { NotificationManager } from "react-notifications";

export const Utils = {
  /**
   * To manage notifications globally
   * @param {*} msg
   * @param {*} type
   */
  createNotification(msg, type) {
    switch (type) {
      case "info":
        NotificationManager.info(msg);
        break;
      case "success":
        NotificationManager.success(msg);
        break;
      case "warning":
        NotificationManager.warning(msg);
        break;
      case "error":
        NotificationManager.error(msg);
        break;
      default:
        break;
    }
  },
  getVideoDuration(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const media = new Audio(reader.result);
        media.onloadedmetadata = () => {
          resolve(media.duration);
        };
      };
      reader.readAsDataURL(file);
      reader.onerror = error => reject(error);
    });
  },
  fmtMSS(s) { 
    return (s - (s %= 60)) / 60 + (9 < s ? ':' : ':0') + s;
  }
}

export default Utils;
