import {Input} from 'antd';
import {Col, Row, Button, Image,} from 'antd';
import {useState} from 'react';

import Utils from '../utils';
import Api from '../interceptor/api';

let path = process.env.PUBLIC_URL;
let messageExclamation = path + "/images/message-exclamation.svg";

const ReportBug = (props) => {
  const [complain, setComplain] = useState('');
  const [disableButton, setDisableButton] = useState(false)

  const submitComplaint = async () => {
    try {
      if (complain) {
        setDisableButton(true)
        await Api.post('/users/report', {
          complaint: complain
        })
        setComplain('')
        setDisableButton(false)
        Utils.createNotification('Complaint registered', 'success')
      } else {
        Utils.createNotification('Please enter complaint', 'error')
      }
    } catch (error) {
      console.log(error)
    }
  }


  return (
    <Row className="report-bug-container">
      <Col>
        <h1>Report a Bug</h1>
        <Row>
          <h3>Please describe the issue</h3>
          <Input.TextArea
            onChange={e => setComplain(e.target.value)}
            value={complain}
            rows={4}
            prefix={<Image src={messageExclamation} preview={false}/>}
            placeholder='Enter your complaint'></Input.TextArea>
          <Button disabled={disableButton} style={{marginTop: '1rem'}} onClick={submitComplaint}>Submit</Button>
        </Row>
      </Col>
    </Row>
  );
};
export default ReportBug;